<template>
  <div>
    <b-modal
      id="modal-danger"
      v-model="isDeleteModalActive"
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-normal"
      centered>
      <div class="d-block text-center">
        <p class="font-weight-medium text-28">
          Confirm to Delete
        </p>
      </div>
      <div
        class="mx-auto"
        style="width: 80%;">
        <b-row>
          <b-col
            cols="6"
            class="d-flex justify-content-end">
            <span class="font-weight-bolder">ID :</span>
          </b-col>
          <b-col cols="6">
            {{ smsTemplateId }}
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-end">
            <span class="font-weight-bolder">Name :</span>
          </b-col>
          <b-col cols="6">
            {{ smsTemplate.templateName }}
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{}">
        <div class="mx-auto">
          <b-button
            variant="outline-danger"
            style="margin-right: 12px"
            @click="confirmDelete(smsTemplateId)">
            Confirm
          </b-button>
          <b-button
            variant="primary"
            @click="cancelDeleteModal()">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="SmsTemplateDetailForm">
          <b-form
            @submit.prevent="handleSubmit()"
            @reset.prevent="resetForm()">
            <p class="text--weight">
              SMS Template Detail
            </p>
            <b-row>
              <b-col
                sm="12"
                md="5">
                <TextFieldInput
                  v-model="smsTemplate.templateName"
                  :rules="{ required: false }"
                  name="หัวข้อ"
                  label="หัวข้อ"
                  placeholder="หัวข้อ"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="smsTemplate.message"
                  :name="`message`"
                  :label="`ข้อความ`" />
              </b-col>
              <!-- <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="smsTemplate.remark"
                  :name="`remark`"
                  :label="`Remark`" />
              </b-col> -->
            </b-row>
            <StatusRadioButton :status.sync="smsTemplate.status" />

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn-submit"
                type="submit"
                @click="editSmsTemplate(smsTemplate)">
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn-submit"
                variant="outline-secondary"
                @click="backRoute()">
                Cancel
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon ml-auto"
                @click="openDeleteModal()">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import SmsTemplateProvider from '@/resources/SmsTemplateProvider'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const SmsTemplateService = new SmsTemplateProvider()

export default {
  components: {
    TextFieldInput,
    StatusRadioButton,
    TextAreaInput
  },
  props: {
    initialSmsTemplate: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isDeleteModalActive: false,
      smsTemplate: {
        templateName: '',
        message: '',
        status: ''
        // remark: ''
      },
      deleteItem: {}
    }
  },
  computed: {
    smsTemplateId () {
      return this.$route.params.id
    }
  },
  created () {
    this.getSmsTemplateById(this.smsTemplateId)
  },
  methods: {
    async getSmsTemplateById (id) {
      try {
        const { data } = await SmsTemplateService.findOne(id)
        this.smsTemplate.templateName = data.templateName
        this.smsTemplate.message = data.message
        this.smsTemplate.status = data.status
      } catch (error) {
        console.log('error', error)
      }
    },
    async editSmsTemplate (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const data = await SmsTemplateService.update(this.smsTemplateId, payload)
        console.log('data', data)
        this.$router.push({ name: 'sms-template' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 SMS Template has been updated'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 SMS Template has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        await SmsTemplateService.softDelete(val)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 SMS Template has been deleted'
          }
        })
        this.$router.push({ name: 'sms-template' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 SMS Template has been Failed ${error}`
          }
        })
      }
    },
    backRoute () {
      this.$router.push({ name: 'sms-template' })
    },
    openDeleteModal () {
      this.isDeleteModalActive = true
    },
    cancelDeleteModal () {
      this.isDeleteModalActive = false
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
